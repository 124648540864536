<template>
  <div :class="contentClassObject">
    <router-view />
  </div>
</template>

<script>
import { VIEW_TEMPLATE } from '@/constants/ViewTemplate';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  setup: (props, context) => {
    const contentBlockName = 'l-content';

    const contentHasHeadImageClass = computed(() => {
      if (!context.root.$route.meta.hasHeadImage) return;
      return (
        contentBlockName +
          '--' +
          'pt0' +
          ' ' +
          contentBlockName +
          '--' +
          'white01Bg' || ''
      );
    });

    const mypageMileClass = computed(() => {
      if (context.root.$route.name !== 'MypageMile') return;
      return (
        contentBlockName +
          '--' +
          'pt0' +
          ' ' +
          contentBlockName +
          '--' +
          'pb96' +
          ' ' +
          contentBlockName +
          '--' +
          'beige03Bg' || ''
      );
    });

    const contentPb0Class = computed(() => {
      if (
        ['ContentStudy', 'LinkLineError', 'LinkLineErrorBlock'].includes(
          context.root.$route.name
        )
      ) {
        return contentBlockName + '--' + 'pb0';
      }
      return '';
    });

    const contentPt0Class = computed(() => {
      if (
        [
          'Notice',
          'MypageGift',
          'MypageGiftDetail',
          'Menu',
          'LinkLineError',
          'LinkLineErrorBlock',
          'Version',
          'Stage',
          'ProDetail',
          'MypageFavorite'
        ].includes(context.root.$route.name)
      ) {
        return contentBlockName + '--' + 'pt0';
      }
      return '';
    });

    const contentBgColorClass = computed(() => {
      if (!context.root.$route.meta.bgColor) return;
      return contentBlockName + '--' + context.root.$route.meta.bgColor;
    });

    /**
     * アプリ側で表示するテンプレートに応じて、付与するクラスを調整する
     */
    const viewTemplateClass = computed(() => {
      if (
        [VIEW_TEMPLATE.SINGLE, VIEW_TEMPLATE.DETAIL].includes(
          context.root.$route.meta.template
        )
      ) {
        return [contentBlockName + '--' + 'pb0'];
      }
      return [];
    });

    const contentClassObject = computed(() => {
      return [
        contentBlockName,
        contentHasHeadImageClass.value,
        mypageMileClass.value,
        contentPb0Class.value,
        contentPt0Class.value,
        contentBgColorClass.value,
        ...viewTemplateClass.value
      ];
    });

    context.root.$log.debug('UA :>> ', window.navigator.userAgent);

    return {
      contentClassObject
    };
  }
});
</script>
